import "./App.css";
import book from "./book.json";
import output from "./output.json";

function App() {
  const kijiNubmer = 10;

  return (
    <div className="App">
      {Object.keys(output).map((key) => (
        <div class="card">
          <div class="row">
            <img
              src={`/picture/${output[key].image}`}
              alt="preview"
              width="100px"
              hspace="5"
              vspace="5"
            />
            <div>
              <div class="rankscore">
                <div class="rank">&emsp;{Number(key) + 1}&emsp;</div>
                <div class="score">
                  score:&nbsp;{output[key].likes_count_sum}
                </div>
              </div>
              <div class="title">{output[key].bookTitle}</div>
            </div>
          </div>
          <div>
            <div class="mb">著者：{output[key].author}</div>
          </div>
          <div>
            <div>引用されているQiita記事トップ5</div>
            <ul>
              {output[key].articles.slice(0, 5).map((article, index) => (
                <li key={index}>
                  <a href={`${article.url}`}>{article.title}</a>
                  <span style={{ "font-size": "150%", color: "red" }}>
                    {" "}
                    &hearts;
                  </span>
                  {article.likes_count}
                </li>
              ))}
            </ul>
            <div>
              <a
                href={`//af.moshimo.com/af/c/click?a_id=3522334&p_id=170&pc_id=185&pl_id=4062&url=https%3A%2F%2Fwww.amazon.co.jp%2Fdp%2F${output[key].amazon}`}
                rel="nofollow"
                referrerpolicy="no-referrer-when-downgrade"
              >
                Amazon.co.jp
              </a>
              <img
                src="//i.moshimo.com/af/i/impression?a_id=3522334&p_id=170&pc_id=185&pl_id=4062"
                width="1"
                height="1"
                alt=""
              />
            </div>
          </div>
        </div>
      ))}
    </div>
  );
}

export default App;
